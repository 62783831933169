import Layout from "../../components/Layout";
import appleLogo from "./../../public/apple-logo.svg";
import appleLogoDark from "./../../public/apple-logo-dark.svg";
import facebookLogo from "./../../public/facebook-logo.svg";
import googleLogo from "./../../public/google-logo.svg";
import { useRouter } from "next/router";
import { useCustomTranslation } from '@utils/translations';
import React, { useEffect, useRef, useState } from "react";
import { Input } from "../../components/Input/Input";
import validate, { getInputValue, InputTypes, YupSchemaObject } from "../../components/utils/validate";
import { OAuthProvider, getAuth, signInWithPopup, FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { initializeApp } from "firebase/app";
import * as Yup from "yup";
import useGtagLayer from "@components/hooks/useGtagLayer";
import Script from 'next/script';

import {
    Login,
} from "../../components/interfaces/types";
import axios from "axios";
import {
    urlLoginPage,
    urlCheckSession,
} from "../../components/utils/server-links";
import useStore from "../../components/store";
import Link from "next/link";
import {
    createCookie,
    removeCookie,
    getCookie,
} from "../../components/utils/helpers";
import withLoadedData from "../../components/hooks/withLoadedData";
import { AnySchema } from 'yup';
import ButtonLoader from '../../components/Buttons/ButtonLoader';
import EmailSuggestion from "../../components/EmailSuggestion/EmailSuggestion";
import HTMLRender from "@components/utils/htmlrender";
import RightSectionSlides from 'containers/RegisterLoginRightSlides';

const initialValues: Login = {
    email: '',
    password: '',
    cfTurnstileToken: ''
};
const firebaseConfig = JSON.parse(process.env.NEXT_PUBLIC_FIREBASE_CONFIG_JSON || '{}');

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const listOfDomains = ['gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com', 'aol.com', 'mail.com'];

const LoginPage: any = () => {
    const { pushGtagData } = useGtagLayer();
    const router = useRouter();
    const { t } = useCustomTranslation();
    const [isLoginFailed, setIsLoginFailed] = useState(false);
    const [showLoginError, setShowLoginError] = useState("");
    const [values, setValues] = useState<Record<string, any>>(initialValues);
    const [errors, setErrors] = React.useState<Record<string, any>>({
        password: {},
        email: {}
    });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [is18orAbove, setIs18OrAbove] = useState(false);
    const saveUserInStore = useStore((state) => state.setUser);
    const defaultCountryOptions = useStore(state => state.defaultCountryOptions);
    const countryLicenses = useStore(state => state.countryLicenses);
    const [defaultLicense, setDefaultLicense] = useState<any>(null);
    const [errorMessage, setErrorMessage] = useState<any>("");
    const [showEmailSuggestions, setShowEmailSuggestions] = useState(false);
    const locale = router?.locale || 'en';
    // hardcode it to true, in order to disable it temporarely 
    const [captchaChallenge, setCaptchaChallenge] = useState(true);
    const allLicenses = useStore(state => state.allLicenses);
    const emailSuggestionRef = useRef<HTMLButtonElement>();
    const [checkSessionLoader, setCheckSessionLoader] = useState<boolean>(false);

    const routerInstrumentType = router?.query?.instrumentType as string || 'trade';
    const [isIframe, setIsIframe] = useState(false)

    const queryParams = router.asPath.split('?')[1];


    const validationSchema: YupSchemaObject<Login> = {
        email: Yup.string().required({ message: 'login.email_required' }).matches(/^[a-zA-Z0-9!@#$%^&*()-=_+{}\[\]|\\;:'",.<>/?]+$/,  {message: {message: 'register.password_require_6'}}).email({ message: 'login.invalid_email' }),
        password: Yup.string() .required({ message: 'register.password_require_1' }),
        cfTurnstileToken: Yup.string(),
        // cfTurnstileToken: Yup.string(),
    };


    useEffect(() => {
        if (router.isReady) checkLoginSession();
    }, [router.isReady]);

    useEffect(() => {
        if (countryLicenses) {
            const defaultLicense: any = countryLicenses.find((option: any) => option.default);
            if (defaultLicense) {
                setDefaultLicense(defaultLicense.license);
            }
        }

    }, [countryLicenses]);

    useEffect(() => {
        let defaultLicenseLocal: any = null;
        if (countryLicenses) {
          let defaultLicenseFromQuery: any = null;
    
          if (router.query.companyId) {
            defaultLicenseFromQuery = countryLicenses.find((option: any) => option.license.companyId == router.query.companyId);
          }
    
          if (!defaultLicenseFromQuery && router.query.companyId) {
            defaultLicenseFromQuery = allLicenses?.find((option: any) => option.companyId == router.query.companyId);
    
            if (!defaultLicenseFromQuery) {
              router.push('/register');
            } else {
              defaultLicenseLocal = { license: defaultLicenseFromQuery };
            }
          } else if (defaultLicenseFromQuery && router.query.companyId) {
            defaultLicenseLocal = defaultLicenseFromQuery;
          } else {
            defaultLicenseLocal = countryLicenses.find((option: any) => option.default);
          }
          if (defaultLicenseLocal) {
            setDefaultLicense(defaultLicenseLocal.license);

          } else {
            if (countryLicenses && countryLicenses.length) {
              const cfdLicense: any = countryLicenses.find((item: any) => !item.instrumentType || (item.instrumentType && item.instrumentType.name == 'CFD'));
    
              if (cfdLicense) {
                setDefaultLicense(cfdLicense.license);
              }
            }
          }
        }
    
      }, [countryLicenses]);


    useEffect(() => {
        const query = location.search.replace('?', '').split('&');
        if (query) {
            query.forEach((item: any) => {
                const [key, value] = item.split('=');

                if (key === 'email') {
                    setValues({ email: value });
                }
            })
        }
    }, [router.query]);


    const validateEmailTest = async () => {
        if (values.email !== '') {
            const result = await validate(values.email, validationSchema.email);
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: result.errors ? result.errors : {}
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: {}
            }));
        }
    };

    useEffect(() => {
        validateEmailTest()
    }, [values.email])

    useEffect(() => {
        validateEmailTest()
    }, [])

    useEffect(() => {
        if (showEmailSuggestions) {
          emailSuggestionRef.current?.click();
        }
      }, [showEmailSuggestions]);

    const clickApple = () => {
        const provider = new OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');

        signInWithPopup(auth, provider)
            .then((result) => {
                // The signed-in user info.
                const user = result.user;

                // Apple credential
                const credential: any = OAuthProvider.credentialFromResult(result);
                const accessToken = credential.accessToken;
                const idToken = credential.idToken;
            })
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The credential that was used.
                const credential = OAuthProvider.credentialFromError(error);

                // ...
            });
    };

    const clickFacebook = () => {
        const provider = new FacebookAuthProvider();
        // provider.addScope('email');
        // provider.addScope('name');

        signInWithPopup(auth, provider)
            .then((result) => {
                // The signed-in user info.
                const user = result.user;

                // Apple credential
                const credential: any = OAuthProvider.credentialFromResult(result);
                const accessToken = credential.accessToken;
                const idToken = credential.idToken;
                // ...
            })
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The credential that was used.
                const credential = OAuthProvider.credentialFromError(error);

                // ...
            });
    };

    const clickGoogle = () => {
        const provider = new GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');

        signInWithPopup(auth, provider)
            .then((result) => {
                // The signed-in user info.
                const user = result.user;

                // Apple credential
                const credential: any = OAuthProvider.credentialFromResult(result);
                const accessToken = credential.accessToken;
                const idToken = credential.idToken;

                // ...
            })
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The credential that was used.
                const credential = OAuthProvider.credentialFromError(error);

                // ...
            });
    }

    const fetchData = async (data: Login) => {
        try {
            const response: any = await axios({
                url: `${process.env.NEXT_PUBLIC_BE_HOST}${urlLoginPage}`,
                method: 'post',
                data: { data: data },
                validateStatus: function (status: any) {
                    if (status === 200) return true;
                    if (status === 429) return false;
                    if (status >= 403) return true;
                    return false;
                },
                withCredentials: true,
                headers: {
                    'Screen-Origin-Url': window?.location?.href
                }
            });

            if (response.data.error) {
                setIsSubmitting(false);
                if (response?.data?.error?.message.includes('captcha')) {
                    const captchaRefresh = document?.querySelector('#cloudflare-captcha-widget > iframe')?.getAttribute('id');
                    if (window && window.turnstile) {
                        window?.turnstile?.reset(captchaRefresh);
                        setCaptchaChallenge(false)
                    }
                } else {
                    setErrorMessage(response?.data?.error?.message || response?.data?.error || 'register.errorOnRegister');
                }
            } else if (response.data && response.data.url) {
                const sessionId = response?.data?.session || '';
                createCookie('JSESSIONID', sessionId);
                await pushGtagData({
                    event: 'login_success',
                    event_category: 'onboarding',
                    Identifier: response?.data?.customerId
                })
               window.location.href = response.data.url;
            }
        } catch (e: any) {
            if (e?.response?.data) {
                setIsLoginFailed(true);
                if (e?.response?.data?.error?.message === "login.email_does_not_exists" || e?.response?.data?.error?.message === "login.email_or_password_wrong") {
                    setIsSubmitting(false);
                    setShowLoginError(e?.response?.data?.error.message);
                } else {
                    setTimeout(function () {
                        if (process.env.NEXT_PUBLIC_APP_ENV === 'local' || process.env.NEXT_PUBLIC_APP_ENV === 'development') {
                            window.location.href = `https://cosmos-cfdglobal-live.az-qa.com/trading-platform/#/login?funnel.registration.enabled=false`;
                        } else {
                            window.location.href = `https://live-cosmos.capex.com/trading-platform/#/login?use.external.login.page=false`;
                        }
                    }, 3000);
                }
            } else if (!e.response) {
                setIsLoginFailed(true);
                setShowLoginError(t('error.too_many_requests'));
            }
        }
    };
    const checkLoginSession = async () => {
        const sessionCookie = getCookie('JSESSIONID');
        if(sessionCookie){
            // set loader of the screen
            setCheckSessionLoader(true)
            try {
                const response: any = await axios({
                    url: `${process.env.NEXT_PUBLIC_BE_HOST}${urlCheckSession}`,
                    method: 'post',
                    data: {
                        session: sessionCookie
                    },
                    headers: {
                        'Screen-Origin-Url': window?.location?.href
                    }
                });
                if (response.data.error) {
                    console.log('error ', response.data.error)
                } else if (response.data.status && response.data.redirectUrl) {
                    window.location.href = response.data.redirectUrl
                } else {
                    removeCookie('JSESSIONID');
                    // stop the loader
                    setCheckSessionLoader(false)
                }
            } catch (e: any) {
                removeCookie('JSESSIONID');
                setCheckSessionLoader(false)
            }
        }
    }

    const handleChange = async (ev: React.ChangeEvent<InputTypes>, inputSchema: AnySchema) => {
        ev.preventDefault();

        const { currentTarget } = ev;

        const input = currentTarget as EventTarget & HTMLInputElement;
        if (input.name === 'email') {
            if (input.value.includes('@') && input.value[input.value.length - 1] === '@') {
                setShowEmailSuggestions(true);
            } else {
                setShowEmailSuggestions(false);
            }

            setErrorMessage('');
            setShowLoginError('');
        }

        setValues((prevValues) => ({
            ...prevValues,
            [input.name]: getInputValue(input)
        }));

        const inputValue = getInputValue(input);

        const result = await validate(inputValue, inputSchema);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [input.name]: result.errors ? result.errors : {}
        }));
    }

    
    

    const onRemoveValueCallback = (inputName: string) => {
        setShowEmailSuggestions(false);
        setValues((prevValues) => ({
            ...prevValues,
            [inputName]: ''
        }));
    }
    const validateEmail = async (ev: React.FocusEvent<HTMLInputElement, Element>) => {
        ev.preventDefault();

        if (ev.target.value !== '') {
            const result = await validate(ev.target.value, validationSchema.email);
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: result.errors ? result.errors : {}
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: {}
            }));
        }
    }

    useEffect(() => {
        if (isIframe) {
            (window as any).iFrameResizer = { log: true, checkOrigin: false }
        }
    }, [isIframe])

    useEffect(() => {
        setIsIframe(router?.query?.iframe === 'true')
    }, [router.query])

    const handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        setIsSubmitting(true);
        setErrorMessage('');
        
        const data = {
            email: values.email,
            password: values.password,
        }

        const urlEncodedData = Object.keys(data)
        // @ts-ignore
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
        .join('&');

        const fetchURL = process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 'https://syn-api.capex.com/user_login' : 'https://dev-syn-api.capex.com/user_login'

        const request = fetch(fetchURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: urlEncodedData
        }).then((response) => response.json()).then((response) => {
            if (response.success) {
                if (isIframe) {
                    if (window.top && window.top.location) {
                        window.top.location.href = window.location.href = response.data[0].url;
                    }
                } else {
                    if (window.location) {
                        window.location.href = response.data[0].url;
                    }
                }
            } else {
                setIsSubmitting(false);
                if (response?.m === 1) {
                    setErrorMessage(<HTMLRender data={t('login.migrated')} />);
                } else if (response?.info?.message?.includes('invalid login or password')) {
                    setErrorMessage(<HTMLRender data={t('login.invalid_creds')} />);
                } else {
                    setErrorMessage(<HTMLRender data={t('forms.general_err')} />);
                }
            }
        })
        .catch((error) => {
            setErrorMessage(<HTMLRender data={t('forms.general_err')} />);
            setIsSubmitting(false);
        });
    };
    
    const handleEmailDomain = async (domain: any) => {
        const user = values.email.substring(0, values.email.lastIndexOf('@'));
        const selectableDomain = values.email.substring(values.email.lastIndexOf('@') + 1);
        setShowEmailSuggestions(false);
        if (selectableDomain !== domain) {
            setValues((prevValues) => ({
                ...prevValues,
                email: user + '@' + domain
            }));
        } else {
            setValues((prevValues) => ({
                ...prevValues,
                email: values.email + domain
            }));
        }
        const result = validate(values.email, validationSchema.email);
        setErrors((prevErrors) => ({
            ...prevErrors,
            email: result.errors ? result.errors : {}
        }));
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        validateEmail(e);
        setShowEmailSuggestions(false);
    }

    const resetTurnstile = () => {
        return false;
        const captchaRefresh = document?.querySelector('#cloudflare-captcha-widget > iframe')?.getAttribute('id');
        window?.turnstile?.reset(captchaRefresh);
        setCaptchaChallenge(false)
    }

    return (
        <>
            <Layout screenHeight isIframe={isIframe} fullWidth title="Existing account - capex onboarding">
            {checkSessionLoader && <div className='absolute w-full h-full left-0 top-o bg-white bg-opacity-70 z-10'><div className="loader absolute left-1/2 top-1/2 -mt-[15px] -ml-[15px]"></div></div>}
                <div className={isIframe ? `py-[20px]` : `lg:py-[20px] h-full flex items-center rtl:justify-end`}>
                    <div className={`lg:flex lg:rtl:flex-row-reverse ${isIframe ? '' : 'lg:w-[73%]'}`}>
                    <div className={`text-center  mx-auto lg:w-[55%] px-5 lg:px-0  ${isIframe ? `lg:max-w-[400px]` : `py-9 lg:pt-[100px] lg:pb-[50px] 3xl:py-[40px] lg:max-w-[660px]`}`}>
                            {!isIframe && <h1 className='text-3xl md:text-4xl text-dark-gray dark:text-white mb-4  font-["Gilroy"] font-bold'>
                                {t("login.title")}
                            </h1>}
                            <p className="text-sm mb-4  text-[#999]">
                                {t("login.switch_to")}{" "}
                                <Link
                                    id="btn_register"
                                    href={`/register${queryParams ? `?${queryParams}` : ''}`}
                                    className="font-semibold text-blue hover:underline"
                                >
                                    {t("login.registration")}
                                </Link>
                            </p>
                            <form autoComplete="false" onSubmit={(e) => handleSubmit(e)}>
                                <div className={`relative mb-4`}>
                                    <EmailSuggestion
                                        errorMessage={errorMessage}
                                        errors={errors.email}
                                        handleChange={handleChange}
                                        onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => handleBlur(e)}
                                        onFocus={() => setShowEmailSuggestions(true)}
                                        onRemove={onRemoveValueCallback}
                                        handleEmailDomain={handleEmailDomain}
                                        showEmailSuggestions={showEmailSuggestions}
                                        validationSchema={validationSchema.email}
                                        domains={listOfDomains}
                                        values={values.email} />
                                </div>
                                <Input
                                    value={values.password}
                                    onChange={(e) => handleChange(e, validationSchema.password)}
                                    type="password"
                                    id="password"
                                    name="password"
                                    label={t("login.password")}
                                    error={errors.password}
                                    required
                                    forceWhiteBgInputLabel
                                />

                                <div className="flex justify-between items-center my-4">
                                    <div className={`text-left flex flex-row items-center`}>
                                        <input
                                            id="rememberMe"
                                            type="checkbox"
                                            value={rememberMe.toString()}
                                            onChange={() => setRememberMe(!rememberMe)}
                                            className="w-4 h-4 ltr:mr-2 rtl:ml-2 rounded text-blue dark:text-gray bg-gray-100 border-gray-300 focus:ring-blue dark:focus:ring-blue dark:ring-offset-gray-800 focus:ring-1 dark:bg-dark-mode dark:border-gray-600"
                                            checked={rememberMe}
                                        />
                                        <label
                                            htmlFor="rememberMe"
                                            className={
                                                !rememberMe
                                                    ? "text-base leading-6 text-gray  w-full"
                                                    : "text-base leading-6 text-black dark:text-white w-full"
                                            }
                                        >
                                            {t('login.stay_signin')}
                                        </label>
                                    </div>
                                    <div className="text-left ltr:pl-4 rtl:pr-4 flex-col flex md:flex-row md:items-center justify-between">
                                        <p className={``}>
                                            <Link
                                                id="btn_forgot_pass"
                                                href={`/user/forgot${queryParams ? `?${queryParams}` : ''}`}
                                                className="text-dark-gray dark:text-white underline text-sm font-bold"
                                            >
                                                {t("forgot.title")}?
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                                {/* {
                                    process.env.NEXT_PUBLIC_CLOUDFLARE_CAPTCHA_SITEKEY && (
                                        <div className="cf-turnstile my-6">

                                            <Turnstile
                                                onSuccess={() => setCaptchaChallenge(true)}
                                                onError={() => resetTurnstile()}
                                                onExpire={() => resetTurnstile()}
                                                id="cloudflare-captcha-widget"
                                                // @ts-ignore
                                                options={{ theme: "light", language: locale == 'de' ? 'en' : locale  }}
                                                siteKey={process.env.NEXT_PUBLIC_CLOUDFLARE_CAPTCHA_SITEKEY} />
                                        </div>
                                    )} */}
                                <p className={`text-red pr-2 text-sm text-left`}>{isLoginFailed ? t(showLoginError) : ' '}</p>
                                <div className="text-left grid grid-cols-1 mt-6 mb-6">
                                    <ButtonLoader
                                        buttonRed
                                        id="login_btn_submit"
                                        classes={`w-full text-white`}
                                        isDisabled={!captchaChallenge || isSubmitting || !(values.password !== '' && errors.password && Object.keys(errors.password).length === 0 && values.email !== '' && errors.email && Object.keys(errors.email).length === 0)}
                                        buttonText={t("login.btn_text")}
                                        buttonType="submit" isLoading={isSubmitting}/>
                                </div>
                                {errorMessage ? <p className={`text-red pr-2 text-sm text-left mb-6`}>{errorMessage}</p> : ''}
                            </form>
                            {
                                defaultCountryOptions?.socialLogin &&
                                <div className="mt-6">
                                    <div className="block text-xs text-dark-gray dark:text-white relative orRegister">
                                        <span className="relative bg-white dark:bg-dark-mode inline-block z-10 px-[16px]">
                                            {t("login.or_register_with")}
                                        </span>
                                    </div>

                                    <div className="grid grid-cols-6 mt-7 mb-6">
                                        <div className="col-span-2">
                                            <div
                                                onClick={clickGoogle}
                                                className="h-full flex items-center justify-center mr-4 rounded-[5px] py-[6px] border-[0.3px] border-solid border-gray"
                                            >
                                                <img
                                                    width={37}
                                                    className="inline-block"
                                                    src={googleLogo.src}
                                                    alt="Google Logo"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-2">
                                            <div
                                                onClick={clickFacebook}
                                                className="h-full flex items-center justify-center mx-2 rounded-[5px] py-[6px] border-[0.3px] border-solid border-gray"
                                            >
                                                <img
                                                    width={32}
                                                    className="inline-block"
                                                    src={facebookLogo.src}
                                                    alt="Facebook Logo"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-2">
                                            <div
                                                onClick={clickApple}
                                                className="h-full flex items-center justify-center ml-4 rounded-[5px] py-[6px] border-[0.3px] border-solid border-gray"
                                            >
                                                <img
                                                    width={28}
                                                    className="inline-block dark:hidden"
                                                    src={appleLogo.src}
                                                    alt="Apple Logo"
                                                />
                                                <img
                                                    width={28}
                                                    className="hidden dark:inline-block"
                                                    src={appleLogoDark.src}
                                                    alt="Apple Logo"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div>
                                <div className="mb-6 w-full border-t-[0.5px] border-solid border-gray"></div>

                                <div id="login_disclaimer" className="text-gray text-xs leading-4"><HTMLRender data={t(`risk_warning.${'sc'}.${routerInstrumentType}.info_text`)} /></div>
                            </div>
                        </div>

                        {!isIframe &&
                            <div className='hidden lg:block w-[27%] fixed bottom-0 right-0 h-[100vh]'>
                                <RightSectionSlides defaultLicenseName={'sc'} productType={routerInstrumentType} />
                            </div>
                        }
                    </div>
                </div>
            </Layout>
            <Script src="https://challenges.cloudflare.com/turnstile/v0/api.js" strategy='lazyOnload'></Script>
        </>
    );
};

// @ts-ignore
export default withLoadedData(LoginPage);
