import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules'; // Import the Pagination module
import 'swiper/css';
import 'swiper/css/pagination'; // Import Swiper styles for pagination
import 'swiper/css/navigation'; // Import Swiper navigation styles
import './RegisterLoginRightSlides.module.css'; // Your custom stylesheet
import { useRouter } from "next/router";
import HTMLRender from '@components/utils/htmlrender';
import { useCustomTranslation } from '@utils/translations';

interface RightSectionProps {
  defaultLicenseName: string;
  productType?: string;
}

const RightSectionSlides = ({ defaultLicenseName, productType }: RightSectionProps) => {
  const { t } = useCustomTranslation();
  const router = useRouter();
  const [currentLocale, setCurrentLocale] = React.useState(router?.locale || 'en');
  useEffect(() => {
    // when locale changes, update the currentLocale
    if (router?.locale && router?.locale !== currentLocale) {
      setCurrentLocale(router?.locale || 'en');
    }
  }, [router?.locale]);

  return (
    <div className="box-wrapper" key={`swiper-container-locale-${currentLocale}`}>
      <Swiper
        key={`swiper-locale-${currentLocale}`} // Rerender Swiper on locale change
        spaceBetween={0}
        grabCursor={true}
        dir={currentLocale === 'ar' ? 'rtl' : 'ltr'}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        loop={true}
        slidesPerView='auto'
        pagination={{ clickable: true }} // Enable pagination
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        modules={[Pagination, Navigation]} // Use Pagination module
      >
        <SwiperSlide>
          <div className="swiper-text">
            <h3 className='!text-white'>
              <HTMLRender data={t('register_slide_1_title')}/>
            </h3>
            <p className='!text-white'>
              <HTMLRender data={t('register_slide_1_content')}/>
            </p>
          </div>
          <img src="https://cdn.capex.com/uploads/slide_one_ebe391674e/slide_one_ebe391674e.png" alt="Slide 1" />
        </SwiperSlide>

        <SwiperSlide>
          <div className="swiper-text">
          <h3 className='!text-white'>
              <HTMLRender data={t('register_slide_1_title')}/>
            </h3>
            <p className='!text-white'>
              <HTMLRender data={t('register_slide_2_content')}/>
            </p>
          </div>
          <img src="https://cdn.capex.com/uploads/slide_two_b35cb403d3/slide_two_b35cb403d3.png" alt="Slide 2" />
        </SwiperSlide>

        <SwiperSlide>
          <div className="slide-three swiper-text justify-end">
          <h3 className='!text-white'>
              <HTMLRender data={t('register_slide_1_title')}/>
            </h3>
            <p className='!text-white'>
              <HTMLRender data={t('register_slide_3_content')}/>
            </p>
          </div>
          <img src="https://cdn.capex.com/uploads/slide_three_c149b9a72e/slide_three_c149b9a72e.png" alt="Slide 3" />
        </SwiperSlide>
      </Swiper>
      <div className={`${currentLocale === 'ar' ? 'swiper-btn-prev-rtl' : '' } swiper-button-prev`}></div>
      <div className={`${currentLocale === 'ar' ? 'swiper-btn-next-rtl' : '' } swiper-button-next`}></div>
    </div>
  );
};

export default RightSectionSlides;
